import domReady from './utils/domReady';

const components = [
	['body > footer', () => import(/* webpackPrefetch: true */'./components/analytics')],
	['comp-takeover', () => import(/* webpackPrefetch: true */'./components/takeover')],
	['html', () => import(/* webpackPrefetch: true */'./components/root')],
	['comp-feature:not([multi-image]):not([crop="Wide"]), ul', () => import(/* webpackPrefetch: true */'./components/anim')],
	['comp-calc', () => import(/* webpackPrefetch: true */'./components/calc')],
	['comp-data-tabs', () => import(/* webpackPrefetch: true */'./components/dataTabs')],
	['comp-dealers', () => import(/* webpackPrefetch: true */ './components/dealers')],
	['[timeranges] > section > div', () => import(/* webpackPrefetch: true */'./components/dealerTimeRanges')],
	['comp-explorer', () => import(/* webpackPrefetch: true */'./components/explorer')],
	['nav[icons]', () => import(/* webpackPrefetch: true */'./components/iconNav')],
	['comp-tabs', () => import(/* webpackPrefetch: true */'./components/tabs')],
	['comp-timeline', () => import(/* webpackPrefetch: true */'./components/timeline')],
	['social-links', () => import(/* webpackPrefetch: true */'./components/socialLinks')],
	['comp-glue[showcase]', () => import(/* webpackPrefetch: true */'./components/vertShowcase')],
	['comp-resources', () => import(/* webpackPrefetch: true */'./components/resources')],
	['form[filters]', () => import(/* webpackPrefetch: true */'./components/formFilters')],
	['form[dynamic]', () => import(/* webpackPrefetch: true */'./components/formDynamic')],
	['comp-press', () => import(/* webpackPrefetch: true */'./components/press')],
	['comp-video', () => import(/* webpackPrefetch: true */'./components/videoInline')],
	['comp-videos', () => import(/* webpackPrefetch: true */'./components/videosPlayer')],
	['body', () => import(/* webpackPrefetch: true */'./components/videoModal')],
	['[footer-actions]', () => import(/* webpackPrefetch: true */'./components/footer-actions')],
	['[data-react]', () => import(/* webpackPrefect: true */'./components/react/index.jsx')]
];

domReady.then(()=> components.forEach(
	([elements,loader]) => [...document.querySelectorAll(elements)].forEach(
		async (element, index) => (await loader()).default(element,index)
	)
));
